import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Modal from "@material-ui/core/Modal"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"

//images
// import Roof1 from "../images/industrial/Roof1.jpeg"
// import Roof2 from "../images/industrial/Roof2.jpeg"
// import Roof5 from "../images/industrial/Roof5.jpeg"
// import roof4 from "../images/industrial/roof4.jpeg"
// import Roof6 from "../images/industrial/Roof6.jpeg"
// import Roof7 from "../images/industrial/Roof7.jpeg"
// import Roof8 from "../images/industrial/Roof8.jpeg"
// import side_before from "../images/industrial/side_before.jpeg"
// import side_after from "../images/industrial/side_after.jpeg"
// import siding_1 from "../images/industrial/siding_1.jpeg"

const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
})

const cards = [
  // {
  //   img: Roof1,
  // },
  // {
  //   img: Roof2,
  // },
  // {
  //   img: Roof5,
  // },
  // {
  //   img: Roof6,
  // },
  // {
  //   img: Roof7,
  // },
  // {
  //   img: Roof8,
  // },
  // {
  //   img: roof4,
  // },
  // {
  //   img: side_before,
  // },
  // {
  //   img: side_after,
  // },
  // {
  //   img: siding_1,
  // },
]

function industrial(props) {
  const { classes } = props

  return (
    <Layout>
      <div>
        <div className="spaceWaster" />
        <main>
          {/* Hero unit */}
          <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
                style={{ fontSize: "56px" }}
              >
                INDUSTRIAL
              </Typography>

              <Typography
                component="h1"
                variant="h6"
                align="center"
                color="textPrimary"
                gutterBottom
                style={{ paddingBottom: "1vh" }}
              >
                NATURE BREAKS IT. WE FIX IT.
              </Typography>

              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              >
                American Dream Construction has handled the construction and
                renovation of industrial plants and warehouses for over twenty
                years.
              </Typography>

              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              >
                We have worked in the worst environments that Alaska has to
                offer, from <strong>Dutch Harbor to the North Slope.</strong>{" "}
                ADC has proven time and again that no matter how poor the
                weather, we can build or repair what our clients need to
                continue their business.{" "}
              </Typography>

              <div className={classes.heroButtons}>
                <Grid container spacing={16} justify="center">
                  <Grid item>
                    <a
                      href="mailto:info@adcalaska.com"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Button variant="contained" color="primary" size="large">
                        Get a quote today!
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>

          <div className={classNames(classes.layout, classes.cardGrid)}>
            {/* End hero unit */}
            <Grid container spacing={40}>
              {cards.map(card => (
                <Grid item key={card} xs={12} sm={12} md={4} lg={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.img}
                      alt={card.title}
                    />

                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.title}
                      </Typography>
                      <Typography>{card.details}</Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Button size="small" color="primary">
                      View
                    </Button> */}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </main>
      </div>
    </Layout>
  )
}

industrial.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(industrial)
